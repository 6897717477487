<script setup lang="ts">
import type { Variable } from '@respell/utils';
import { common } from 'lowlight';
import AppInput from '~/components/app/AppInput.vue';
import DraggableInputs from '~/components/editor/config/DraggableInputs.vue';
import ObjectBuilder from '~/components/editor/config/ObjectBuilder.vue';
import TypePicker from '~/components/editor/config/TypePicker.vue';
import { createOptionSchema } from '~/util/validation';

const canvasStore = useCanvasStore();
const spellStore = useSpellsStore();

const { variableState, variable } = storeToRefs(canvasStore);
const { selectedStep } = useSelectedStep();
const { addVariable, generateKey } = canvasStore;
const { spell } = storeToRefs(spellStore);

const form = ref<Form<Variable> | null>(null);

onMounted(async () => {
  try {
    await form.value?.validate();
  } catch (error) {
    // Intentionally empty to prevent throwing errors on validation
  }
});

const isInput = computed(() => variableState.value === 'adding-input');
const forReview = computed(() => selectedStep.value?.type === 'review');

const state = reactiveComputed(() => {
  return {
    type: variable.value?.type ?? 'text/plain',
    name: variable.value?.name ?? undefined,
    description: variable.value?.description ?? undefined,
    isRequired:
      variable.value?.isOptional !== null &&
      variable.value?.isOptional !== undefined
        ? !variable.value.isOptional
        : isInput.value,
    isMultiInput: variable.value?.listDepth ?? 0,
    isDropdown: variable.value?.metadata?.options ?? false,
    hint: variable.value?.metadata?.hint ?? undefined,
    value: variable.value?.value ?? null,
    order: variable.value?.order ?? null,
    allowedLanguages: variable.value?.metadata?.allowedLanguages ?? [],
    dropdownOptions: variable.value?.metadata?.options ?? [
      { key: undefined, name: undefined },
    ],
    schemaEntries: Object.values(
      variable.value?.metadata?.schema ?? {
        '': {
          type: 'text/plain',
          listDepth: 0,
          isOptional: false,
          value: null,
          key: '',
          name: '',
        },
      },
    ),
  };
});

const isText = computed(() => {
  return state.type.includes('text') || state.type.includes('number');
});

const label = computed(
  () =>
    `${variable.value ? 'Edit' : 'Add'} ${isInput.value ? 'Input' : 'Output'}`,
);

const displayOption = computed(() => {
  return {
    key: 'value',
    name: 'Value',
    description:
      isInput.value && forReview.value
        ? 'Set a default value that should be edited upon review'
        : 'Set the value of this output',
    type: state.type,
    listDepth: 0,
    isOptional: isInput.value,
    value: state.value,
  };
});

async function onSubmit(event: FormSubmitEvent) {
  const {
    isRequired,
    isMultiInput,
    isDropdown,
    hint,
    type,
    value,
    dropdownOptions,
    allowedLanguages,
    schemaEntries,
    ...rest
  } = event.data;

  const formattedVariable = {
    ...rest,
    type,
    key:
      variable.value?.key ??
      generateKey(state.name ?? 'Variable', isInput.value ? 'input' : 'output'),
    isOptional: !isRequired,
    listDepth: isMultiInput ? 1 : 0,
    value:
      value ??
      (isMultiInput && type === 'object'
        ? [{}]
        : isMultiInput
          ? []
          : type === 'object'
            ? {}
            : null),
    metadata: {
      ...(forReview.value ? { forReview: true } : {}),
      ...(isDropdown
        ? {
            options: dropdownOptions.map((option) => ({
              ...option,
              key: option.name,
            })),
          }
        : {}),
      ...(type === 'object' && schemaEntries.length
        ? {
            schema: Object.fromEntries(
              canvasStore.generateKeyedList(schemaEntries).map((entry) => [
                entry.key,
                {
                  ...entry,
                  isOptional: !isRequired,
                },
              ]),
            ),
          }
        : {}),
      ...(hint ? { hint } : {}),
      ...(type === 'code' ? { allowedLanguages } : {}),
    },
  };

  await addVariable(
    formattedVariable,
    isInput.value ? 'input' : 'output',
    !!variable.value,
  );
}

function handleDeleteOption(index: number) {
  state.dropdownOptions.splice(index, 1);
}

function handleAddOption() {
  state.dropdownOptions.push({
    key: undefined,
    name: undefined,
    type: state.type,
  });
}

const validate = (state: any): FormError[] => {
  const errors = [];
  if (!state.name) errors.push({ path: 'name', message: 'Required' });
  return errors;
};

const schema = computed(() =>
  createOptionSchema({ value: displayOption.value }),
);
</script>
<template>
  <UForm
    ref="form"
    :schema="isInput ? undefined : schema"
    :validate="validate"
    :state="state"
    class="w-full h-full flex flex-col overflow-hidden justify-start items-start"
    @submit="onSubmit"
  >
    <div class="w-full grow px-l overflow-y-scroll shrink pb-l">
      <UFormGroup
        size="xl"
        :label="label"
        :description="`Choose the type for this ${isInput ? 'input' : 'output'}.`"
        required
        class="mt-m w-full"
      >
        <TypePicker v-model="state.type" />
      </UFormGroup>
      <UFormGroup
        size="xl"
        eager-validation
        name="name"
        required
        label="Label"
        :description="`Choose a label for this ${isInput ? 'input' : 'output'}.`"
        class="mt-m w-full"
      >
        <UInput v-model="state.name" color="white" />
      </UFormGroup>
      <UFormGroup
        size="xl"
        label="Description"
        :description="`Describe the purpose of this ${isInput ? 'input' : 'output'}.`"
        class="mt-m w-full"
      >
        <UInput v-model="state.description" color="white" />
      </UFormGroup>

      <div v-if="isInput" class="contents">
        <UFormGroup
          v-if="isText && !state.isDropdown"
          size="xl"
          label="Placeholder"
          description="Add a placeholder to this input."
          placeholder="Enter text or insert data"
          class="mt-m w-full"
        >
          <UInput v-model="state.hint" color="white" />
        </UFormGroup>
        <div v-if="isText" class="contents">
          <div class="flex flex-row items-center justify-between mt-s py-s">
            <p class="subtitle text-gray-700">Dropdown</p>
            <UToggle v-model="state.isDropdown" color="primary" />
          </div>
          <UFormGroup
            v-if="state.isDropdown"
            size="xl"
            label="Options"
            class="mt-m w-full"
          >
            <DraggableInputs
              v-model="state.dropdownOptions"
              :type="state.type"
              :handle-delete="handleDeleteOption"
              :handle-add="handleAddOption"
              option-type="option"
            />
          </UFormGroup>
        </div>
        <UFormGroup
          v-else-if="state.type === 'code'"
          size="xl"
          required
          label="Allowed Languages"
          description="Select the languages that are allowed to be used in this code input."
          class="mt-m w-full"
        >
          <USelectMenu
            v-model="state.allowedLanguages"
            searchable-placeholder="Search for languages"
            placeholder="Select languages"
            searchable
            multiple
            class="w-full"
            :options="Object.keys(common)"
          />
        </UFormGroup>
        <div class="flex flex-row items-center justify-between mt-s py-s">
          <p class="subtitle text-gray-700">Multiple Inputs</p>
          <UToggle v-model="state.isMultiInput" color="primary" />
        </div>
        <div class="flex flex-row items-center justify-between mt-s py-s">
          <p class="subtitle text-gray-700">Required</p>
          <UToggle v-model="state.isRequired" color="primary" />
        </div>

        <UFormGroup
          v-if="state.type === 'object'"
          size="xl"
          label="Define Schema"
          class="mt-m w-full"
        >
          <ObjectBuilder v-model="state.schemaEntries" />
        </UFormGroup>
      </div>

      <AppInput
        v-if="!isInput || forReview"
        v-model="state.value"
        form-key="value"
        :option="displayOption"
        owner-type="Spell"
        :owner-id="spell?.id"
        class="mt-m"
        injectable
      />
    </div>
    <div
      class="flex mt-auto shrink-0 w-full px-m py-l border-t border-gray-200"
    >
      <UButton
        block
        :ui="{ rounded: 'rounded-full', font: 'font-bold' }"
        size="xl"
        type="submit"
        :disabled="
          form?.errors?.length ||
          (state.isDropdown && state.dropdownOptions?.length < 2)
        "
        >Save
      </UButton>
    </div>
  </UForm>
</template>
