<script setup lang="ts">
import type { AccordionItem, Form } from '#ui/types';
import definitions from '@respell/steps';
import type { Variable } from '@respell/utils';
import AppLinkedAccount from '~/components/app/AppLinkedAccount.vue';
import StepOption from '~/components/editor/config/StepOption.vue';
import StepWarning from '~/components/editor/config/StepWarning.vue';

const canvasStore = useCanvasStore();

const { selectedStep } = useSelectedStep();

const form = ref<Form<Variable> | null>(null);

watchDeep(
  selectedStep,
  async () => {
    try {
      await form.value?.validate();
    } catch (error) {
      // Intentionally empty to prevent throwing errors on validation
    }
  },
  { immediate: true },
);

const validate = (state: any): FormError[] | null | undefined => {
  return canvasStore.validateStep(
    selectedStep.value?.data.slug,
    selectedStep.value?.data.key,
    state,
  );
};

const stepType = computed(() => {
  return definitions[selectedStep.value?.data.key];
});

const hasContext = computed(() => {
  return (
    stepType.value?.services?.length ||
    localContextSteps.includes(stepType.value?.key)
  );
});

const options = computed(() => {
  const grouped = {} as AccordionItem[];
  const ungrouped = [] as Variable[];

  Object.values(stepType.value?.options || {}).forEach((option: Variable) => {
    const groupKey = option.metadata?.group;
    if (groupKey) {
      const group = stepType.value?.groups?.[groupKey];
      if (group) {
        if (!grouped[groupKey]) {
          grouped[groupKey] = {
            label: group.name,
            icon: group.icon,
            defaultOpen: !Object.keys(grouped).length,
            options: [],
          };
        }
        grouped[groupKey].options.push(option);
      }
    } else {
      ungrouped.push(option);
    }
  });

  return { grouped, ungrouped };
});

defineShortcuts({
  backspace: {
    handler: () => {
      canvasStore.removeStep(selectedStep.value?.data.slug as string);
    },
  },
});
</script>
<template>
  <UForm
    v-if="selectedStep"
    ref="form"
    :validate="validate"
    :state="selectedStep.data.options"
    class="grow pb-48"
  >
    <transition name="fade">
      <span v-if="form?.errors?.length" class="px-7 flex">
        <StepWarning :errors="form?.errors" :options="stepType.options" />
      </span>
    </transition>

    <UFormGroup
      v-if="stepType.services?.length"
      size="xl"
      label="Link account to get started"
      required
      class="mb-m relative px-7"
    >
      <AppLinkedAccount
        v-for="service in stepType.services"
        :key="service"
        :service="service"
        :step="selectedStep"
      />
    </UFormGroup>

    <UAccordion
      :items="options.grouped"
      color="gray"
      variant="ghost"
      size="xl"
      :ui="{
        item: {
          padding: 'px-7 mt-2',
        },
        default: {
          class: 'rounded-none w-full mb-2 text-lg px-7',
        },
      }"
    >
      <template #item="{ item }">
        <StepOption
          v-for="option in item.options"
          :key="'ungrouped-' + option.key"
          v-model="selectedStep.data.options[option.key]"
          :option="option"
          :has-error="form?.errors.some((error) => error.path === option.key)"
          :has-context="hasContext"
        />
      </template>
    </UAccordion>

    <StepOption
      v-for="option in options.ungrouped"
      :key="'ungrouped-' + option.key"
      v-model="selectedStep.data.options[option.key]"
      :option="option"
      :has-error="form?.errors.some((error) => error.path === option.key)"
      :has-context="hasContext"
      class="px-7"
    />
  </UForm>
</template>
