<script setup lang="ts">
import { typeMap, type Json, type Variable } from '@respell/utils';
import {
  moveArrayElement,
  useSortable,
} from '@vueuse/integrations/useSortable';
import { nextTick, ref, watch } from 'vue';

const modelValue =
  defineModel<
    { value: string | undefined; type?: string; name?: string; key?: string }[]
  >();

const props = defineProps<{
  optionType: string;
  handleDelete: (key: string | number) => void;
  handleAdd?: () => void;
  type?: string;
  variables?: Json;
}>();

const options = ref(
  modelValue.value ??
    Object.entries(props.variables || {})
      .map(([key, value], index) => ({
        ...value,
        key,
        order: value.order ?? index, // Assign order if not present
      }))
      .sort((a, b) => a.order - b.order),
);

const canvasStore = useCanvasStore();
const el = ref<HTMLElement | null>(null);

const uniqueKeysMap = ref(new Map());

watch(
  options,
  (newOptions, oldOptions) => {
    // Add new unique keys for added items
    newOptions?.forEach((item, index) => {
      if (!uniqueKeysMap.value.has(item)) {
        uniqueKeysMap.value.set(item, Math.random().toString(36).substr(2, 9));
      }
    });

    // Remove unique keys for removed items
    oldOptions?.forEach((item) => {
      if (!newOptions?.includes(item)) {
        uniqueKeysMap.value.delete(item);
      }
    });
  },
  { deep: true },
);

useSortable(el, options, {
  handle: '.dragHandle',
  animation: 150,
  onUpdate: (e) => {
    if (modelValue.value) {
      moveArrayElement(modelValue.value, e.oldIndex, e.newIndex);
    } else {
      const items = options.value;
      const movedItem = items[e.oldIndex];
      items.splice(e.oldIndex, 1);
      items.splice(e.newIndex, 0, movedItem);

      // Update order for all items
      items.forEach((item, index) => {
        item.order = index;
      });

      // Reconstruct the object
      const updatedObject = {};
      items.forEach((item) => {
        updatedObject[item.key] = item;
      });

      Object.values(updatedObject ?? {}).forEach((item: Variable) => {
        canvasStore.addVariable(item, props.optionType, true);
      });
    }
    nextTick();
  },
});

const handleEdit = (option) => {
  if (props.variables) {
    canvasStore.editVariable(option);
  }
};
</script>
<template>
  <div class="flex flex-col items-center gap-3 pt-s">
    <div ref="el" class="contents">
      <div
        v-for="(item, index) in options"
        :key="uniqueKeysMap.get(item)"
        class="flex flex-row w-full justify-between items-center gap-2"
      >
        <UIcon
          name="i-ph-dots-six-vertical-bold"
          class="text-gray-400 dragHandle cursor-move text-4xl hover:text-gray-500 transition-colors"
        />
        <UInput
          v-model="item.name"
          placeholder="Create an option"
          :type="
            variables
              ? 'text'
              : type?.includes('number')
                ? 'number'
                : type?.includes('file')
                  ? 'file'
                  : 'text'
          "
          :ui="variables ? { base: 'cursor-pointer' } : {}"
          color="white"
          class="w-full"
          size="lg"
          :trailing="false"
          :readonly="variables"
          @click="() => handleEdit(item)"
        >
          <template #leading>
            <UIcon
              class="text-2xl text-gray-400"
              :name="
                item.listDepth > 0
                  ? 'i-ph-brackets-square'
                  : typeMap[item.type ?? type ?? 'text/plain'].icon
              "
            />
          </template>
        </UInput>
        <UIcon
          name="i-ph-trash-simple"
          class="text-gray-400 cursor-pointer text-2xl hover:text-gray-500 transition-colors"
          @click="() => handleDelete(item.key ?? index)"
        />
      </div>
    </div>
    <UButton
      v-if="handleAdd"
      icon="i-ph-plus-bold"
      size="md"
      color="gray"
      variant="ghost"
      class="border border-gray-200 border-dashed text-gray-400 m"
      :label="`Add ${optionType}`"
      block
      :ui="{
        size: { lg: 'text-lg' },
        icon: { size: { lg: 'h-5 w-5' } },
      }"
      @click="handleAdd"
    />
  </div>
</template>
