<script setup lang="ts">
import integrations, { triggers } from '@respell/integrations';
import type { Variable } from '@respell/utils';
import AppInput from '~/components/app/AppInput.vue';
import AppLinkedAccount from '~/components/app/AppLinkedAccount.vue';
import ContextInput from '~/components/editor/config/ContextInput.vue';

const canvasStore = useCanvasStore();
const spellId = useRouteParams('spellId');
const { trigger, triggerDefinition } = storeToRefs(canvasStore);

// Clear the trigger's event, options, and linked account when the service changes
watch(
  () => trigger.value?.service,
  () => {
    trigger.value.event = undefined;
    trigger.value.options = {};
    trigger.value.integration = undefined;
  },
);

// Set the trigger's options to default values when the event changes
watch(
  () => trigger.value?.event,
  () => {
    const defaultOptions = Object.keys(
      triggerDefinition.value?.options ?? {},
    ).reduce((acc, key) => {
      acc[key] = triggerDefinition.value.options[key].value;
      return acc;
    }, {});

    trigger.value.options = defaultOptions;
  },
);

const triggerConfig = computed(() => {
  const service: Variable = {
    key: 'service',
    name: 'App',
    isOptional: false,
    type: 'text/plain',
    listDepth: 0,
    metadata: {
      options: Object.values(integrations).filter((integration) =>
        Object.keys(triggers).includes(integration.key),
      ),
      isSearchable: true,
    },
    value: null,
  };

  const event: Variable = {
    key: 'event',
    name: 'Event',
    isOptional: false,
    type: 'text/plain',
    listDepth: 0,
    metadata: {
      options: trigger.value?.service
        ? Object.values(triggers[trigger.value?.service])
        : [],
      isSearchable: true,
    },
    value: null,
  };

  return { service, event };
});
</script>
<template>
  <UForm
    v-if="trigger"
    :state="trigger"
    class="mt-m flex w-full flex-col gap-4"
  >
    <AppInput v-model="trigger.service" :option="triggerConfig.service" />
    <AppInput v-model="trigger.event" :option="triggerConfig.event" />
    <div v-if="trigger?.service" class="contents">
      <div
        v-if="trigger.options && triggerDefinition?.options"
        class="contents"
      >
        <ContextInput
          v-for="(option, key) in triggerDefinition.options"
          :key="key"
          v-model="trigger.options[key]"
          :option="option"
          owner-type="Spell"
          :owner-id="spellId"
          :linked-account="trigger.integration"
          class="w-full"
        />
      </div>
      <UFormGroup
        size="xl"
        label="Link account to get started"
        required
        class="w-full"
      >
        <AppLinkedAccount :service="trigger.service" :trigger="trigger" />
      </UFormGroup>
    </div>
  </UForm>
</template>
