<script setup lang="ts">
import { PostHogEvents } from '@respell/utils/tracking/types';
import { useVueFlow } from '@vue-flow/core';
import type { StepEntity } from 'v-onboarding';
import {
  VOnboardingStep,
  VOnboardingWrapper,
  useVOnboarding,
} from 'v-onboarding';

const canvasStore = useCanvasStore();
const tutorialStore = useTutorialStore();
const userStore = useUserStore();

const wrapper = ref(null);
const previousViewport = reactive({ x: 0, y: 0 });
const {
  onMove,
  addSelectedNodes,
  removeSelectedNodes,
  getViewport,
  setViewport,
  fitView,
} = useVueFlow({
  id: 'editor',
});

const { startType, trigger, elements, graph } = storeToRefs(canvasStore);
const { start, goToStep, finish } = useVOnboarding(wrapper);
const { selectedStep, addingStep } = useSelectedStep();

const STEP_INDICES = {
  ADD_START: 0,
  SELECT_START_TYPE: 1,
  CONFIG_START: 2,
  ADD_MORE: 3,
  USE_VARIABLES: 4,
  ADD_DISPLAY: 5,
  ADD_OUTPUT: 6,
  TEST_AND_PUBLISH: 7,
  //   FIND_TUTORIAL: 8,
};

const { hasTestedSpell, tutorialStep, state } = storeToRefs(tutorialStore);
const hasCreatedInput = computed(
  () => Object.keys(graph.value?.inputs ?? {}).length > 0,
);
const hasConfiguredTrigger = computed(
  () => trigger.value?.event && trigger.value?.service,
);
const hasAddedStep = computed(() =>
  elements.value.some((node) => node.type === 'step'),
);
const hasAddedDisplay = computed(() =>
  elements.value.some((node) => node.type === 'display'),
);
const hasCreatedOutput = computed(
  () => Object.keys(graph.value?.outputs ?? {}).length > 0,
);

async function panCanvas(offset = 400) {
  const viewport = getViewport();
  await setViewport(
    { x: viewport.x - offset, y: viewport.y, zoom: viewport.zoom },
    { duration: 200 },
  );
}

const steps = computed(() => {
  const baseSteps: StepEntity[] = [
    {
      attachTo: { element: '#add-start-node', classList: ['radiate'] },
      content: {
        title: 'Set up your spell start',
        description:
          '<p>The Start step is what kicks off your workflow. Click the box below to create it.</p>',
      },
      placement: 'top',
    },
    {
      attachTo: { element: '#choose-start-label' },
      content: {
        title: 'Select start type',
        description: `
        <p>Choose the type of spell you want to create:</p>
        <ul class='list-disc ml-5'">
          <li class='mb-1'>Manual spells are started by entering the inputs yourself or via the API.</li>
          <li class='mb-1'>Trigger spells start when an event happens in a third party app (such as when you receive a new email).</li>
        </ul>
        <p><a href="https://docs.respell.ai/documentation/getting-started/manual-trigger-spells" target="_blank" class='underline underline-offset-4'>Learn more</a></p>
      `,
        gif: '/tutorial/choose-start-type.gif',
      },
      placement: 'left-start',
      on: {
        async beforeStep() {
          if (!selectedStep.value) {
            const addStartNode = elements.value.find(
              (node) => node.type === 'add-start',
            );
            addSelectedNodes([addStartNode]);
          }
          await panCanvas();
        },
      },
    },
  ];

  baseSteps.push(
    startType.value === 'trigger'
      ? {
          attachTo: { element: '#trigger-settings' },
          content: {
            title: 'Define the trigger',
            description:
              "<p>Select the app and action the spell should be triggered from. If you need a trigger we don't have yet, you can <a href='https://join.slack.com/t/respellcommunity/shared_invite/zt-2hu3jrvs7-OOIprhyCGIeOB8hiy~AmOg' target='_blank' class='underline underline-offset-4'>request one here</a>.</p>",
            gif: '/tutorial/define-trigger.gif',
          },
          placement: 'left-end',
          on: {
            async afterStep() {
              if (
                startType.value === 'trigger' &&
                !hasConfiguredTrigger.value
              ) {
                trigger.value = {
                  service: 'gmail',
                };
                await new Promise((resolve) => setTimeout(resolve, 250));
                trigger.value.event = 'emails';
                trigger.value.options = {};
              }
            },
          },
        }
      : {
          attachTo: { element: '#start-settings' },
          content: {
            title: 'Create an input',
            description: `<p>To start a spell, you fill out inputs in the spell page. You can configure your inputs in the Start step.<br/><br/><a href="https://docs.respell.ai/documentation/getting-started/manual-trigger-spells#defining-inputs" target="_blank" class='underline underline-offset-4'>Learn more</a></p>`,
            gif: '/tutorial/create-input.gif',
          },
          placement: 'left-end',
          on: {
            beforeStep: () => {
              if (!startType.value) {
                canvasStore.updateStartType('start');
              }
            },
            afterStep: () => {
              if (!hasCreatedInput.value) {
                canvasStore.addVariable(
                  {
                    name: 'Input',
                    description: 'This is an example input variable',
                    type: 'text/plain',
                    key: 'input',
                    isOptional: false,
                    listDepth: 0,
                    value: null,
                    metadata: {
                      hint: 'Enter value here',
                    },
                  },
                  'input',
                );
              }
            },
          },
        },
  );

  baseSteps.push(
    {
      attachTo: {
        element: '#start-add-step-button',
        classList: ['radiate-rounded'],
      },
      content: {
        title: 'Add more steps',
        description: `<p>Spells can be as simple or complex as you need. You can add new steps by clicking the (+) button or by dragging them in from the sidebar on the left.<br/><br/>If you need some ideas on what comes next, check out our <a href="https://app.respell.ai/templates" target="_blank" class='underline underline-offset-4'>templates</a>.<br/><br/><a href="https://docs.respell.ai/documentation/learning-respell/spell-editor/steps-connections" target="_blank" class='underline underline-offset-4'>Learn more</a></p>`,
        gif: '/tutorial/add-step.gif',
      },
      placement: 'right',
      on: {
        async beforeStep() {
          if (selectedStep.value) {
            removeSelectedNodes([selectedStep.value]);
          }
        },
        async afterStep() {
          if (!hasAddedStep.value) {
            const startNode = elements.value.find(
              (node) => node.type === 'start' || node.type === 'trigger',
            );
            if (!startNode) {
              return;
            }
            const newStep = canvasStore.addStep('generate_text', {
              x: startNode?.position.x + 450,
              y: startNode?.position.y,
            });
            canvasStore.addEdge({
              source: startNode.id,
              target: newStep.id,
              type: 'step',
            });
            setTimeout(() => {
              addSelectedNodes([newStep]);
            }, 50);
          }
        },
      },
    },
    {
      attachTo: { element: '#step-settings' },
      content: {
        title: 'Configure options and use variables from previous steps',
        description: `<p>Variables are how data passes from one step to another. Your inputs are variables, and any outputs a step produces are also variables.<br/><br/>Every option in a step can use variables. Try adding a variable to one of the options in this step.<br/><br/><a href="https://docs.respell.ai/documentation/getting-started/step-options-variables" target="_blank" class='underline underline-offset-4'>Learn more</a></p>`,
        gif: '/tutorial/add-variable.gif',
      },
      placement: 'left',
      on: {
        async beforeStep() {
          if (addingStep.value) {
            await until(addingStep).toBeUndefined();
          }
          if (!selectedStep.value) {
            await until(selectedStep).not.toBeUndefined();
          }
          await panCanvas();
        },
        afterStep() {
          if (selectedStep.value) {
            removeSelectedNodes([selectedStep.value]);
          }
        },
      },
    },
    {
      attachTo: {
        element: '#step-add-step-button',
        classList: ['radiate-rounded'],
      },
      content: {
        title: 'Add a display step',
        description: `<p>If you want to see the result of the spell run you have to add a display step and add the value it’s going to show.<br/><br/>You can create multiple outputs in one display block<br/><br/><a href="https://docs.respell.ai/documentation/learning-respell/spell-editor/steps-connections" target="_blank" class='underline underline-offset-4'>Learn more</a></p>`,
        gif: '/tutorial/add-display.gif',
      },
      placement: 'right',
      on: {
        async afterStep() {
          if (!hasAddedDisplay.value) {
            const stepNode =
              elements.value.find((node) => node.type === 'step') ??
              elements.value.find(
                (node) => node.type === 'start' || node.type === 'trigger',
              );
            const displayStep = canvasStore.addStep('display', {
              x: stepNode.position.x + 450,
              y: stepNode.position.y,
            });
            canvasStore.addEdge({
              source: stepNode.id,
              target: displayStep.id,
              type: 'step',
            });
          }
        },
      },
    },
    {
      attachTo: { element: '#display-settings' },
      content: {
        title: 'Create an output',
        description: `<p>Outputs are variables displayed in a display step. You can create and configure your outputs in the Display step.<br/><br/><a href="https://docs.respell.ai/steps-reference/flow-tools/display" target="_blank" class='underline underline-offset-4'>Learn more</a></p>`,
      },
      placement: 'left-end',
      on: {
        async beforeStep() {
          if (!selectedStep.value) {
            const displayStep = elements.value.find(
              (node) => node.type === 'display',
            );
            if (displayStep) {
              addSelectedNodes([displayStep]);
            }
          }
          await panCanvas(200);
        },
        afterStep: () => {
          if (!hasCreatedOutput.value) {
            if (!selectedStep.value) {
              const displayStep = elements.value.find(
                (node) => node.type === 'display',
              );
              if (displayStep) {
                addSelectedNodes([displayStep]);
              }
            }
            canvasStore.addVariable(
              {
                name: 'Output',
                description: 'This is an example output variable',
                type: 'text/plain',
                key: 'output',
                isOptional: false,
                listDepth: 0,
                value: 'Hello world',
              },
              'output',
            );
          }
          if (selectedStep.value) {
            removeSelectedNodes([selectedStep.value]);
          }
        },
      },
    },
    {
      attachTo: { element: '#spell-topbar-actions' },
      content: {
        title: 'Test and Publish your spell',
        description:
          "<p>Once you've added a few steps, test your spell to make sure it's doing what you need.<br/><br/>When you're happy with the results, publish your spell to use it in more ways and share with others!</p>",
        gif: '/tutorial/test-spell.gif',
      },
      placement: 'bottom',
      on: {
        async beforeStep() {
          await fitView({ duration: 200 });
        },
      },
    },
    // TODO: Add back final step when we have more tutorials
    //   {
    //     attachTo: { element: '#editor-zoom-out' },
    //     content: {
    //       title: 'You can find the tutorial and help here!',
    //       description:
    //         'If you want to restart this tutorial or find other tutorials/resources, click here!',
    //     },
    //     placement: 'bottom-start',
    //   },
  );

  return baseSteps;
});

function advance(index: number) {
  tutorialStep.value = index;
  goToStep(index);
}

onMove((event) => {
  const { flowTransform } = event;

  if (
    [
      STEP_INDICES.ADD_START,
      STEP_INDICES.ADD_MORE,
      STEP_INDICES.ADD_DISPLAY,
    ].includes(tutorialStep.value)
  ) {
    const stepElement = document.querySelectorAll(
      '[data-popper-placement]',
    )?.[0];

    if (stepElement) {
      const transform = stepElement.style.transform;
      const match = /translate3d\(([^,]+)px, ([^,]+)px, ([^,]+)px\)/.exec(
        transform,
      );

      if (match) {
        const currentX = parseFloat(match[1]);
        const currentY = parseFloat(match[2]);

        const deltaX = flowTransform.x - previousViewport.x;
        const deltaY = flowTransform.y - previousViewport.y;

        stepElement.style.transform = `translate3d(${currentX + deltaX}px, ${currentY + deltaY}px, 0px)`;
      }
    }
  }

  previousViewport.x = flowTransform.x;
  previousViewport.y = flowTransform.y;
});

function setupWatchers() {
  watch(selectedStep, (newValue) => {
    if (newValue?.type === 'add-start') {
      advance(STEP_INDICES.SELECT_START_TYPE);
    } else if (!newValue && !startType.value) {
      advance(STEP_INDICES.ADD_START);
    } else if (!newValue && tutorialStep.value === STEP_INDICES.USE_VARIABLES) {
      advance(STEP_INDICES.ADD_DISPLAY);
    }
  });

  watch(startType, () => advance(STEP_INDICES.CONFIG_START));
  watchOnce(hasCreatedInput, () => advance(STEP_INDICES.ADD_MORE));
  watchOnce(hasConfiguredTrigger, () => advance(STEP_INDICES.ADD_MORE));
  watchOnce(hasAddedStep, () => {
    if (!selectedStep.value && !addingStep.value) {
      setTimeout(() => {
        const newNode = elements.value.find((node) => node.type === 'step');
        if (newNode) {
          addSelectedNodes([newNode]);
        }
      }, 100);
    }
    advance(STEP_INDICES.USE_VARIABLES);
  });
  watchOnce(hasAddedDisplay, () => advance(STEP_INDICES.ADD_OUTPUT));
  watchOnce(hasCreatedOutput, () => advance(STEP_INDICES.TEST_AND_PUBLISH));
  watchOnce(hasTestedSpell, () => handleFinish());
}

const handleFinish = async () => {
  finish();
  state.value = 'inactive';
  await userStore.updateProfile({
    milestones: [...(userStore.profile?.milestones || []), 'tutorial'],
  });
};

onMounted(() => {
  setTimeout(() => {
    setupWatchers();
    start();
    goToStep(tutorialStep.value);
  }, 500);
});
</script>
<template>
  <VOnboardingWrapper
    ref="wrapper"
    :steps="steps"
    :options="{
      popper: {
        placement: steps[tutorialStep]?.placement ?? 'auto',
        modifiers: [
          {
            name: 'offset',
            options: {
              offset: [0, 20],
            },
          },
        ],
      },
      overlay: {
        enabled: false,
        preventOverlayInteraction: false,
      },
      hideNextStepDuringHook: true,
    }"
  >
    <template #default="{ step, isLast, index }">
      <VOnboardingStep>
        <div
          class="bg-primary-600 shadow rounded-xl flex flex-col gap-3 items-start p-4 w-96"
        >
          <span class="flex justify-between items-start w-full">
            <p class="title text-white">{{ step.content.title }}</p>
            <UButton
              :padded="false"
              color="primary"
              variant="link"
              icon="i-ph-x"
              :ui="{
                variant: {
                  link: 'text-primary-300 hover:text-primary-100',
                },
              }"
              @click="state = 'hidden'"
            />
          </span>
          <img
            v-if="step.content.gif"
            :src="step.content.gif"
            class="w-full rounded-lg"
          />
          <p class="body text-primary-200" v-html="step.content.description" />
          <span class="flex w-full justify-between">
            <span class="flex gap-2">
              <UIcon
                v-for="i in steps.length"
                :key="i"
                name="i-ph-circle-fill"
                class="transition-colors duration-300 w-2 h-2"
                :class="i === index + 1 ? 'text-white' : 'text-primary-300'"
              />
            </span>
            <UButton
              v-if="!isLast"
              variant="solid"
              color="primary"
              label="Next"
              size="xl"
              @click="() => advance(index + 1)"
            />
            <UButton
              v-else
              v-posthog-capture="PostHogEvents.UserCompletedTutorial"
              variant="solid"
              color="primary"
              label="Finish"
              size="xl"
              @click="handleFinish"
            />
          </span>
        </div>
      </VOnboardingStep>
    </template>
  </VOnboardingWrapper>
</template>
<style lang="scss">
.radiate,
.radiate-rounded {
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: transparent;
    box-sizing: border-box;
    border: #5344e5 1px solid; // Reduced border thickness for subtlety
    z-index: 1;
    animation: radiate 2s ease-out infinite; // Slowed down the animation for subtlety
  }
}

.radiate:before {
  border-radius: radius(s); // Use the dynamically set border radius
}

.radiate-rounded:before {
  border-radius: 100%; // Full border radius for rounded elements
}

@keyframes radiate {
  0% {
    transform: scale(1);
    opacity: 0;
  }

  10% {
    opacity: 0.7;
  }

  95%,
  100% {
    transform: scale(1.2);
    opacity: 0;
  }
}
:root {
  --v-onboarding-step-z: 51;
  [data-v-onboarding-wrapper] [data-popper-arrow]::before {
    content: '';
    background: var(--v-onboarding-step-arrow-background, #4936d5);
    top: 0;
    left: 0;
    transition:
      transform 0.2s ease-out,
      visibility 0.2s ease-out;
    visibility: visible;
    transform: translateX(0px) rotate(45deg);
    transform-origin: center;
    width: var(--v-onboarding-step-arrow-size, 15px);
    height: var(--v-onboarding-step-arrow-size, 15px);
    position: absolute;
    z-index: -1;
  }

  --v-onboarding-overlay-z: 0;

  [data-v-onboarding-wrapper]
    [data-popper-placement^='top']
    > [data-popper-arrow] {
    bottom: 10px !important;
    left: 10px !important;
  }

  [data-v-onboarding-wrapper]
    [data-popper-placement^='right']
    > [data-popper-arrow] {
    left: -4px;
  }

  [data-v-onboarding-wrapper]
    [data-popper-placement^='bottom']
    > [data-popper-arrow] {
    top: -4px;
  }

  [data-v-onboarding-wrapper]
    [data-popper-placement^='left']
    > [data-popper-arrow] {
    right: 10px !important;
    top: 8px !important;
  }
}
</style>
