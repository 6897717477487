<script setup lang="ts">
import definitions from '@respell/steps';
import type { Variable } from '@respell/utils';
import { useVueFlow } from '@vue-flow/core';
import AppInput from '~/components/app/AppInput.vue';
import DraggableInputs from '~/components/editor/config/DraggableInputs.vue';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';
import StepWarning from './StepWarning.vue';

const canvasStore = useCanvasStore();
const modal = useModal();
const { selectedStep } = useSelectedStep();
const { variableState } = storeToRefs(canvasStore);
const { removeNodes } = useVueFlow({
  id: 'editor',
});

const form = ref<Form<Variable> | null>(null);

watchDeep(
  selectedStep,
  async () => {
    try {
      await form.value?.validate();
    } catch (error) {
      // Intentionally empty to prevent throwing errors on validation
    }
  },
  { immediate: true },
);

const validate = (state: any): FormError[] | null | undefined => {
  return canvasStore.validateStep(
    selectedStep.value?.data.slug,
    selectedStep.value?.data.key,
    state,
  );
};

const options = definitions.review.options;

const variables = computed(() =>
  canvasStore.fetchReferences(selectedStep.value?.id),
);

const inputs = computed(() => variables.value?.inputs);
const outputs = computed(() => variables.value?.outputs);

const handleAddInput = () => {
  variableState.value = 'adding-input';
};

const handleAddOutput = () => {
  variableState.value = 'adding-output';
};

const handleDeleteInput = (key: string) => {
  canvasStore.removeVariable(key, 'input');
};

const handleDeleteOutput = (key: string) => {
  canvasStore.removeVariable(key, 'output');
};

defineShortcuts({
  backspace: {
    handler: () => {
      if (
        Object.keys(inputs.value).length ||
        Object.keys(outputs.value).length
      ) {
        modal.open(ConfirmModal, {
          type: 'review step',
          action: 'delete',
          message: 'All existing review variables will be lost.',
          isDangerous: true,
          onConfirm() {
            deleteReview();
            modal.close();
          },
        });
      } else {
        deleteReview();
      }
    },
  },
});

const deleteReview = () => {
  Object.keys(outputs.value).forEach((key) => {
    canvasStore.removeVariable(key, 'output');
  });
  Object.keys(inputs.value).forEach((key) => {
    canvasStore.removeVariable(key, 'input');
  });
  removeNodes([selectedStep.value?.id]);
};
</script>
<template>
  <UForm
    v-if="selectedStep"
    ref="form"
    :validate="validate"
    :state="selectedStep.data.options"
    class="w-full"
  >
    <transition name="fade">
      <StepWarning v-if="form?.errors?.length" :errors="form?.errors" />
    </transition>
    <AppInput
      v-model="selectedStep.data.options.instruction"
      :option="definitions.review.options.instruction"
      class="mb-l"
    />
    <UFormGroup
      size="xl"
      name="outputs"
      :label="options.outputs.name"
      :description="options.outputs.description"
      class="mb-l w-full"
    >
      <DraggableInputs
        :variables="outputs"
        :handle-delete="handleDeleteOutput"
        :handle-add="handleAddOutput"
        option-type="output"
      />
    </UFormGroup>
    <UFormGroup
      size="xl"
      :label="options.inputs.name"
      name="inputs"
      :description="options.inputs.description"
      class="mb-l w-full"
    >
      <DraggableInputs
        :variables="inputs"
        :handle-delete="handleDeleteInput"
        :handle-add="handleAddInput"
        option-type="input"
      />
    </UFormGroup>
  </UForm>
</template>
