<script setup lang="ts">
import { useVueFlow } from '@vue-flow/core';
import DraggableInputs from '~/components/editor/config/DraggableInputs.vue';
import ConfirmModal from '~/components/modals/ConfirmModal.vue';

const canvasStore = useCanvasStore();
const modal = useModal();
const { selectedStep } = useSelectedStep();
const { variableState, graph } = storeToRefs(canvasStore);
const { removeNodes } = useVueFlow({
  id: 'editor',
});

const isDisplay = computed(() => selectedStep.value?.type === 'display');
const variables = computed(() => {
  return isDisplay.value
    ? canvasStore.fetchReferences(selectedStep.value?.id)?.outputs
    : graph.value?.inputs;
});

const handleAdd = () => {
  variableState.value = isDisplay.value ? 'adding-output' : 'adding-input';
};

const handleDelete = (key: string) => {
  canvasStore.removeVariable(key, isDisplay.value ? 'output' : 'input');
};

defineShortcuts({
  backspace: {
    whenever: [isDisplay],
    handler: () => {
      if (Object.keys(variables.value).length) {
        modal.open(ConfirmModal, {
          type: 'display step',
          action: 'delete',
          message: 'All existing output variables will be lost.',
          isDangerous: true,
          onConfirm() {
            deleteDisplay();
            modal.close();
          },
        });
      } else {
        deleteDisplay();
      }
    },
  },
});

const deleteDisplay = () => {
  Object.keys(variables.value).forEach((key) => {
    canvasStore.removeVariable(key, 'output');
  });
  removeNodes([selectedStep.value?.id]);
};
</script>
<template>
  <UFormGroup
    size="xl"
    required
    :label="`Add ${isDisplay ? 'output' : 'input'}`"
    :description="`Create more ${isDisplay ? 'outputs' : 'inputs'}`"
    class="mt-m w-full max-h-full"
  >
    <DraggableInputs
      :variables="variables"
      :handle-delete="handleDelete"
      :handle-add="handleAdd"
      :option-type="isDisplay ? 'output' : 'input'"
    />
  </UFormGroup>
</template>
